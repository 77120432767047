/* eslint-disable react/require-default-props */
import React from 'react'
import { cn } from '~/utils/cn'

export type HeroProps = {
  videoProps?: React.ComponentPropsWithoutRef<'video'>
  isContentVisible?: boolean
  children: React.ReactNode
} & React.ComponentPropsWithoutRef<'section'>

const Hero = ({
  isContentVisible = false,
  videoProps = undefined,
  children,
  ...restSectionProps
}: HeroProps) => {
  return (
    <section className="relative h-svh w-full bg-white" {...restSectionProps}>
      <video
        width="100%"
        height="100%"
        autoPlay
        muted
        disablePictureInPicture
        preload="metadata"
        playsInline
        {...videoProps}
        className={cn(
          'absolute inset-0 h-full w-full object-cover transition-opacity duration-200',
          {
            'opacity-0': isContentVisible
          }
        )}
      />
      <div className="absolute inset-0 h-full w-full">{children}</div>
    </section>
  )
}

export default Hero
